<template>
    <b-modal ref="editModal" 
        centered
        :title="title && title"
        @ok="$emit('save')"
        :ok-title="$t(tKey + 'saveOk')"
        :cancel-title="$t(tKey + 'saveCancel')"
        ok-variant="success"
    >
        <p class="lead" v-html="$t(tKey + 'saveWarning')" />
    </b-modal>
</template>

<script>
export default {
    name: "EditModal",
    props: {
        title: { type: String, required: false },
        user: { type: Object, required: false },
    },
    data() {
        return {
            tKey: 'admin.modal.'
        }
    },
    methods: {
        show() {
            this.$refs.editModal.show()
        },
    },
}
</script>

<style>

</style>