import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            users: null
        }
    },
    computed: {
        ...mapGetters('auth', ['email'])
    },
    methods: {
        fetchUsers() {
            axios.post("api/Account/FetchUsers", {
                requester: this.email,
                aspNetRole: this.$staticVariables.getAspNetRole(),
              })
                .then((resp) => {
                    this.users = JSON.parse(resp.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async addUser(user) {
            await axios.post('api/Account/CreateUser', {
                username: user.username,
                email: user.email,
                password: user.password,
                role: user.role || 3,
                firstname: user.firstname,
                lastname: user.lastname,
                requester: this.email,
                aspNetRole: this.$staticVariables.getAspNetRole(),                
                password: `${this.$staticVariables.getAspNetRole()}2022!`, 
            }).then(() => {
                this.fetchUsers()
                this.$bvToast.toast(this.$t(`toasts.admin.create.success.body`), {
                    title: this.$t(`toasts.admin.create.success.title`),
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                    appendToast: true,
                })
            }).catch((err) => {
                console.log(err)
                this.$bvToast.toast(this.$t(`toasts.admin.create.failure.body`), {
                    title: this.$t(`toasts.admin.create.failure.title`),
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                    appendToast: true,
                })
            })                    
        },
        async deleteUser(user) {
            await this.axios.delete('api/Account/DeleteUser', {
                data: {
                    requester: this.email,
                    userId: user.Id,
                }
              })
                .then(() => {
                    this.fetchUsers()
                    this.$bvToast.toast(this.$t(`toasts.admin.delete.success.body`), {
                        title: this.$t(`toasts.admin.delete.success.title`),
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                        appendToast: true,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    this.$bvToast.toast(this.$t(`toasts.admin.delete.failure.body`), {
                        title: this.$t(`toasts.admin.delete.failure.title`),
                        variant: 'danger',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                        appendToast: true,
                    })
                })
        },
        async editUser(user) {
            await this.axios.put('api/Account/EditUser', {
                requester: this.email,
                userId: user.Id,
                username: user.newUsername,
                email: user.newEmail,
                firstname: user.newFirstName,
                lastname: user.newLastName,
                role: parseInt(user.newRolesId),
                aspNetRole: this.$staticVariables.getAspNetRole(),                
            })
                .then(() => {
                    this.fetchUsers()
                    this.$bvToast.toast(this.$t(`toasts.admin.edit.success.body`), {
                        title: this.$t(`toasts.admin.edit.success.title`),
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                        appendToast: true,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    this.$bvToast.toast(this.$t(`toasts.admin.edit.failure.body`), {
                        title: this.$t(`toasts.admin.edit.failure.title`),
                        variant: 'danger',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                        appendToast: true,
                    })
                })
        },
    }
}