<template>
    <b-modal ref="deleteModal" 
        centered
        :title="title && title"
        @ok="$emit('delete')"
        :ok-title="$t(tKey + 'deleteOk')"
        :cancel-title="$t(tKey + 'deleteCancel')"
        ok-variant="danger"
    >
        <p class="lead" v-html="$t(tKey + 'deleteWarning')" />
    </b-modal>
</template>

<script>
export default {
    name: "DeleteModal",
    props: {
        title: { type: String, required: false },
    },
    data() {
        return {
            tKey: 'admin.modal.'
        }
    },
    methods: {
        show() {
            this.$refs.deleteModal.show()
        },
    },
}
</script>

<style>

</style>