<template>
    <div>
        <h1 class="text-left pb-3" v-html="$t(tKey + 'header')" />
        <SearchBar class="pb-4" @search="(q) => query = q">
            <b-button variant="link" @click="attemptCreateUser">
                <font-awesome-icon class="mr-1" icon="plus" />
                <span v-html="$t(tKey + 'create')" />
            </b-button>
        </SearchBar>
        <div v-if="filteredUsers && filteredUsers.length">
            <b-table
                small
                striped
                :items="filteredUsers"
                :fields="fields"
                caption-top
                :currentPage="currentPage"
                :perPage="perPage"                
            >
                <template #table-caption>
                    <span
                        v-html="`${filteredUsers.length} ${$tc(tKey + 'usersFound', filteredUsers.length)}`"
                    />
                    <b-button class="ml-2 text-success" size="sm" variant="link" @click="exportExcel">
                        <font-awesome-icon class="mr-2" icon="file-excel" />
                        <span v-html="$t(tKey + 'excel')" />
                    </b-button>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-end">
                        <template v-if="!row.item.isEditing">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="toggleEditing(row.item)"
                                class="mr-2 rounded-circle"
                            >
                                <font-awesome-icon icon="edit" />
                            </b-button>
                            <b-button
                                size="sm"
                                variant="danger"
                                @click="attemptDeleteUser(row.item)"
                                class="mr-2 rounded-circle"
                                :disabled="row.item.Roles[0].Id == '2'"
                            >
                                <font-awesome-icon icon="trash" />
                            </b-button>
                        </template>
                        <template v-else>
                            <b-button
                                size="sm"
                                variant="success"
                                @click="attemptEditUser(row.item)"
                                class="mr-2 rounded-circle"
                            >
                                <font-awesome-icon icon="save" />
                            </b-button>
                            <b-button
                                size="sm"
                                variant="dark"
                                @click="toggleEditing(row.item)"
                                class="mr-2 rounded-circle"
                            >
                                <font-awesome-icon icon="times-circle" />
                            </b-button>
                        </template>
                    </div>
                </template>
                <template #cell(Fullname)="row">
                    <div v-if="row.item.isEditing">
                        <b-form-input type="text" v-model="row.item.newFirstName" />
                        <b-form-input type="text" v-model="row.item.newLastName" />
                    </div>
                    <div v-else>
                        <span v-html="`${row.item.FirstName} ${row.item.LastName}`" />
                    </div>
                </template>
                <template #cell(Username)="row">
                    <div v-if="row.item.isEditing">
                        <b-form-input type="text" v-model="row.item.newUsername" />
                    </div>
                    <div v-else>
                        <span v-html="row.item.Username" />
                    </div>
                </template>
                <template #cell(Email)="row">
                    <div v-if="row.item.isEditing">
                        <b-form-input type="text" v-model="row.item.newEmail" />
                    </div>
                    <div v-else>
                        <span v-html="row.item.Email" />
                    </div>
                </template>
                <template #cell(Roles)="row">
                    <div v-if="row.item.isEditing">
                        <b-form-select v-model="row.item.newRolesId" :options="rolesOptions" />
                    </div>
                    <div v-else>
                        <span v-html="$t(`${tKey}roles.${row.item.Roles[0].Name}`)" />
                    </div>
                </template>
            </b-table>
            <b-pagination
                v-if="rows > perPage"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="questions-table"
            />
        </div>
        <div v-else>
            <h4 class="text-center text-muted font-italic" v-html="$t(tKey + 'noUsers')" />
        </div>
        <DeleteModal
            v-if="selectedUser"
            ref="deleteModal"
            :title="`${selectedUser.FirstName} ${selectedUser.LastName}`"
            @delete="deleteUser(selectedUser)"
        />
        <EditModal
            v-if="selectedUser"
            ref="editModal"
            :title="`${selectedUser.FirstName} ${selectedUser.LastName}`"
            @save="prepareUserEdition"
        />
        <AddModal ref="addModal" :title="$t(tKey + 'create')" @save="addUser" />
    </div>
</template>

<script>
import AdminMixin from '@/mixins/api/admin'
import SearchBar from '@/components/utils/SearchBar'
import DeleteModal from '@/components/admin/DeleteModal'
import EditModal from '@/components/admin/EditModal'
import AddModal from '@/components/admin/AddModal'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
    components: { SearchBar },
    name: 'Admin',
    mixins: [AdminMixin],
    components: {
        SearchBar,
        DeleteModal,
        EditModal,
        AddModal,
    },
    data() {
        return {
            tKey: 'admin.',
            query: '',
            selectedUser: null,
            currentPage: 1,
            perPage: 10,
        }
    },
    computed: {
        excelHeading() {
            return [
                this.$t('admin.modal.firstname.label'),
                this.$t('admin.modal.lastname.label'),
                this.$t('admin.modal.email.label'),
                this.$t('admin.modal.username.label'),
                this.$t('settings.Profile.PhoneNumber.label'),
                this.$t('admin.roles.title'),
            ]
        },
        rows() {
            return this.filteredUsers.length
        },
        filteredUsers() {
            if (!this.query || this.query === '') return this.users
            const query = this.query.toLowerCase()
            return this.users.filter((u) => u.Username.toLowerCase().includes(query)
                || u.Email.toLowerCase().includes(query)
                || u.FirstName.toLowerCase().includes(query)
                || u.LastName.toLowerCase().includes(query)
                || u.Roles.some((r) => r.Name.toLowerCase().includes(query))
            )
        },
        fields() {
            return [
                {
                    key: 'Fullname',
                    label: this.$t(this.tKey + 'name'),
                    sortable: true,
                    formatter: (value, key, item) => {
                        return `${item.FirstName} ${item.LastName}`
                    }
                },
                {
                    key: 'Username',
                    label: this.$t(this.tKey + 'username'),
                    sortable: true,
                },
                {
                    key: 'Email',
                    label: this.$t(this.tKey + 'email'),
                    sortable: true,
                },
                {
                    key: 'Roles',
                    label: this.$t(this.tKey + 'roles.title'),
                    sortable: true,
                    formatter: (value, key, item) => {
                        return this.$t(`${this.tKey}roles.${item.Roles[0].Name}`)
                    }
                },
                {
                    key: 'actions',
                    label: '',
                    sortable: false,
                },
            ]
        },
        rolesOptions() {
            return [
                { value: 2, text: this.$t("admin.roles.DmoAdmin") },
                { value: 3, text: this.$t("admin.roles.DmoEmployee") },
                { value: 4, text: this.$t("admin.roles.Partner") }
            ]
        }
    },
    created() {
        this.fetchUsers()
    },
    methods: {
        toggleEditing(item) {
            item.isEditing = !item.isEditing
            if (item.isEditing) {
                item.newUsername = item.Username
                item.newEmail = item.Email
                item.newLastName = item.LastName
                item.newFirstName = item.FirstName
                item.newRolesId = item.Roles[0].Id
            } else {
                delete item.newUsername
                delete item.newEmail
                delete item.newLastName
                delete item.newFirstName
                delete item.newRolesId
            }
            const index = this.users.findIndex((u) => u.Id === item.Id)
            this.users.splice(index, 1, item)
        },
        attemptCreateUser() {
            this.$refs.addModal.show()
        },
        attemptEditUser(item) {
            this.selectedUser = item
            this.$nextTick(() => {
                this.$refs.editModal.show()
            })
        },
        attemptDeleteUser(item) {
            this.selectedUser = item
            this.$nextTick(() => {
                this.$refs.deleteModal.show()
            })
        },
        async prepareUserEdition() {
            let user = this.users.find((u) => u.Id === this.selectedUser.Id)
            this.editUser(user)
        },
        exportExcel() {
            const formattedUsers = [...this.filteredUsers].map((u) => {
                u.Role = this.rolesOptions.find((r) => r.value == u.Roles[0].Id).text
                delete u.Id
                delete u.Roles
                delete u.Type
                return u
            })
            const excelSheet = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet([]);
            var heading = [
                this.excelHeading
            ];
            XLSX.utils.sheet_add_aoa(ws, heading)
            XLSX.utils.sheet_add_json(ws, formattedUsers, { origin: 'A2', skipHeader: true });
            XLSX.utils.book_append_sheet(excelSheet, ws, this.$t('admin.users'));
            XLSX.writeFile(excelSheet, 'export.xlsx')
        },
    }
}
</script>

<style>
</style>